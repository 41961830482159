import React, { Component, ReactNode } from 'react';

import WrapperAuth from 'components/wrappers/Auth';

import { Props } from './index';
import StyledComponent from './styles';

export default class WrapperApp extends Component<Props> {
    static defaultProps = {
        children: null,
    };

    renderWrapper = (): ReactNode => {
        const { children } = this.props;

        return (
            <WrapperAuth>
                {children}
            </WrapperAuth>
        );
    };

    render = (): JSX.Element => {
        return (
            <StyledComponent className="wrapper-app">
                {this.renderWrapper()}
            </StyledComponent>
        );
    };
}
