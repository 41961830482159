import { parseToQueryString } from 'utils/querystring';

import { getKeyValueByString } from './object';

export function shorten(string: string, length: number, addDots = false): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    let newString = string.substring(0, length);
    if (string.length > length && addDots) newString = newString + '...';

    return newString;
}

export function withVariables(string: string, variables = {}, queryObject = {}, hash = ''): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    Object.keys(variables)
        .forEach(variableKey => {
            const pattern = ':variableKey'.replace('variableKey', variableKey);

            string = string.replace(pattern, variables[variableKey]);
        });

    Object.keys(variables)
        .forEach(variableKey => {
            const pattern = '[variableKey]'.replace('variableKey', variableKey);

            string = string.replace(pattern, variables[variableKey]);
        });

    string = string
        .replace('(', '')
        .replace(')', '');

    return parseToQueryString(string, queryObject) + hash;
}

export function isJsonString(jsonString: string): boolean {
    try {
        const o = JSON.parse(jsonString);
        if (o && typeof o === 'object') {
            return o;
        }
    }
    catch (e) {
        // Ignore
    }

    return false;
}


export function replaceVariables(string: string, values: object): string {
    string = decodeHTMLEntities(string);
    const regexp = new RegExp('{{.+?}}', 'g');
    const matches = string.match(regexp);

    if (!Array.isArray(matches)) {
        return string;
    }

    matches.forEach(match => {
        let path = match.match(/\{\{(.*)\}\}/g)[0];
        path = path
            .replace('{', '')
            .replace('{', '')
            .replace('}', '')
            .replace('}', '');

        const value = getKeyValueByString(values, path);
        if (typeof value === 'undefined') {
            return string;
        }

        string = string.replace(match, value);
    });

    return string;
}

export function decodeHTMLEntities(text) {
    const entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"'],
    ];

    for (let i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

    return text;
}

export function capitalizeFirstLetter(string: string): string {
    if (!string) return;
    return  string.charAt(0).toUpperCase() + string.slice(1);
}
