import ApiUser from 'types/api/User';

import Model from 'models/Model';

export default class User implements Model {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    shortName: string;
    phone?: string;
    enabled: boolean;

    constructor(data: ApiUser) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.phone = data.phone;
        this.shortName = this.getShortName();
        this.fullName = this.getFullName();
    }

    getShortName = (): string => {
        return `${this.firstName || '---'} ${this.lastName || '---'}`;
    };

    getFullName = (): string => {
        const details = [];
        if(this.email) details.push(this.email);
        if(this.phone) details.push(this.phone);

        return `${this.firstName || '---'} ${this.lastName || '---'} ${details.length ? `(${details.join(', ')})` : ''}`;
    };
}
