export default {
    fontPrimary: 'Montserrat, sans-serif',
    fontSecondary: 'Nunito, sans-serif',
    fontFallback: 'Helvetica, Arial, sans-serif',

    colorPrimary: '#FFFFFF',
    colorSecondary: '#181719',
    colorTertiary: '#261957',
    colorSelectHover: '#4C107D',
    colorViolet: '#A639FF',
    colorDark: '#0E0E0E',
    colorFullDark: '#000000',
    colorAlert: '#ff9292',
    colorDisabled: '#b7b7b7',
    colorCategoryPurple: '#400E4E',

    colorTextDark: '#333333',
    colorTextLight: '#999999',
    colorTextWhite: '#FFFFFF',
    colorTextGray: '#4B4B4B',

    colorPlanBasic: '#00B7CC',
    colorPlanPremiumLight: '#5476FF',
    colorPlanPremium: '#A639FF',

    backgroundLabel: 'rgba(38,25,87,0.85)',
    backgroundCourseGradient:
        'transparent linear-gradient(180deg, #100C11 0%, #15031F 100%) 0 0 no-repeat padding-box',
    backgroundGradientDarkToPurple:
        'transparent linear-gradient(180deg, #0E0E0E 0%, #3C0043 100%) 0 0 no-repeat padding-box',
    backgroundGradientBlackToPurple:
        'transparent linear-gradient(180deg, #000000 0%, #3C0043 100%) 0 0 no-repeat padding-box',
    backgroundGradientBlackToPurpleSoft:
        'transparent linear-gradient(180deg, #000000 0%, #170519 100%) 0 0 no-repeat padding-box',
    backgroundGradient180:
        'transparent linear-gradient(180deg, #5F1495 0%, #7A297D 100%) 0% 0% no-repeat padding-box',
    backgroundGradient250:
        'transparent linear-gradient(250deg, #5F1495 0%, #7A297D 100%) 0% 0% no-repeat padding-box',
    backgroundGradientMainViolet180:
        'linear-gradient(180deg, rgba(14,14,14,1) 0%, rgba(22,2,33,1) 100%);',
    backgroundGradientCertificateDetails:
        'linear-gradient(90deg, rgba(165,58,255,1) 0%, rgba(0,209,212,1) 100%)',
    backgroundHeadingLine:
    'linear-gradient(270deg, rgba(253,0,255,1) 0%, rgba(253,0,255,1) 11%, rgba(176,62,242,1) 35%, rgba(89,134,227,1) 68%, rgba(0,209,212,1) 100%);',
    backgroundNavigation: '#0E0E0E',
    backgroundWhite: '#FFFFFF',
    backgroundWhiteA80: 'rgba(255, 255, 255, 0.8)',
    backgroundPurplish: '#ccc7de',
    backgroundLightGray: '#F1F1F1',
    backgroundGray: '#414141',
    backgroundDarkViolet: '#160221',
    backgroundDarkGray: '#141414',
    backgroundLayoutOnGray: '#202020',
    backgroundMain: '#0E0E0E',
    backgroundMainOpacity90: 'rgba(14, 14, 14, 0.95)',
    borderColorPurpleLine: '#A639FF',

    transparent: 'transparent',

    desktopXL: '1920px',
    desktopL: '1750px',
    desktopM: '1600px',
    desktopS: '1360px',
    desktopXS: '1200px',

    tabletL: '1024px',
    tabletS: '768px',

    mobileL: '640px',
    mobileM: '480px',
    mobileS: '360px',
    mobileXS: '281px',
};
