import React from 'react';
import { Global } from '@emotion/core';

import stylesBreakpoints from 'theme/styles/breakpoints';
import stylesCookieConsent from 'theme/styles/cookieconsent';
import stylesGlobal from 'theme/styles/global';
import stylesReset from 'theme/styles/reset';

import WrapperApp from 'components/wrappers/App';

function WrapperRoot({ Component, pageProps }) {
    return (
        <>
            <Global
                styles={[
                    stylesReset,
                    stylesGlobal,
                    stylesBreakpoints,
                    stylesCookieConsent,
                ]}
            />
            <WrapperApp {...pageProps}>
                <Component {...pageProps} />
            </WrapperApp>
        </>
    );
}

export default WrapperRoot;