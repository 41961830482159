import { ReactNode } from 'react';
import { NextRouter } from 'next/router';

import Customer from 'models/Customer';

import Component from './component';

export interface Props {
    router?: NextRouter;
    children?: ReactNode;
    state?: ParentState;
    resetWrapperState?: () => void;
    resetCounter?: number;
}

export interface State {
    authenticated?: boolean;
    authToken?: string;
    profile: Customer | null;
    resetCounter?: number;
}

export interface ParentState {
    authToken?: string;
    profile?: Customer | null;
}

export default Component;
