export enum ApiResources {
    Status = 'status',
    Me = 'me',

    Page = 'page',

    AuthToken = 'authToken',
    Customer = 'customer',
    Partner = 'partner',
    PartnerUser = 'partnerUser',

    CourseCategories = 'courseCategories',
    CourseCategory = 'courseCategory',

    Courses = 'courses',
    Course = 'course',

    Plans = 'plans',
    Plan = 'plan',

    Companies = 'partners',
    Company = 'partner',
    ConductList = 'leads',
    Conduct = 'lead',

    Certificate = 'partnerCertificate',

    Survey = 'partnerNps',

    Articles = 'articles',
    Article = 'article',

    Orders = 'orders',
    Order = 'order',

    PaymentTransaction = 'paymentTransaction',

    CourseScheduleCustomers = 'courseScheduleCustomers',
    CourseScheduleCustomer = 'courseScheduleCustomer',

    CustomerLikedCourses = 'customerLikedCourses',
    CustomerLikedCourse = 'customerLikedCourse',

    Contacts = 'contacts',
    Contact = 'contact',
}
