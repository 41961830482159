import getConfig from 'next/config';
import axios, { AxiosRequestConfig } from 'axios';

import { ReduxRequestOptions } from 'types/request';

import { mapErrors } from 'utils/api';
import { parseToQueryString } from 'utils/querystring';
import Logger from 'services/Logger';

export async function request(options: ReduxRequestOptions): Promise<any> {
    options = transformOptions(options);
    const requestConfig = getRequestCofnig(options);

    try {
        let response = null;

        // Make request
        if (!response) {
            response = await axios(requestConfig);
        }

        // Handle response
        if (options.actionsOnCode && options.actionsOnCode[response.status]) {
            options.actionsOnCode[response.status](response);
        }

        return {
            payload: response,
            params: options.params,
        };
    } catch (error) {
        console.error((error && error.response) || error);

        if (options.actionsOnCode && options.actionsOnCode[error?.response?.status]) {
            options.actionsOnCode[error?.response?.status](error?.response);
        }

        Logger.warning('API Error', {
            options,
            response: error.response,
        });

        throw {
            payload: mapErrors(error && error.response),
            params: options.params,
            error,
        };
    }
}

export function mockRequest(options: ReduxRequestOptions): Promise<any> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                payload: options.mock(options.params),
                params: options.params,
            });
        }, 300);
    });
}

function getRequestCofnig(options: any): AxiosRequestConfig {
    return {
        method: options.method.toUpperCase(),
        url: options.url,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            ...options.headers,
        },
        data: options.data,
        timeout: options.timeout || 30000,
        responseType: 'json',
        adapter: options.adapter || undefined,
        validateStatus: (status) => {
            return status >= 200 && status < 300;
        },
        paramsSerializer: (params) => {
            return JSON.stringify(params);
        },
    };
}

function transformOptions(options: ReduxRequestOptions) {
    const { publicRuntimeConfig } = getConfig();

    // Attach headers
    options.headers = {
        ...(options.headers || {}),
    };

    // Ensure that requestParams is object
    if (typeof options.requestParams !== 'object' || !options.requestParams) {
        options.requestParams = {};
    }

    // Support for PUT request methods
    if (options.method.toUpperCase() === 'PUT' && !options.forceMethod) {
        options.method = 'POST';
        options.requestParams._method = 'PUT';
    }

    // Support for DELETE request methods
    if (options.method.toUpperCase() === 'DELETE' && !options.forceMethod) {
        options.method = 'POST';
        options.requestParams._method = 'DELETE';
    }

    if (options.method.toUpperCase() === 'GET') {
        options.path = parseToQueryString(options.path, options.requestParams);
    }

    options.data = options.requestParams;
    if (options.asFormData) {
        const formData = new FormData();
        Object.keys(options.data).forEach((key) => {
            formData.append(key, options.data[key]);
        });
        options.data = formData;
    }

    // Get api path
    options.url = publicRuntimeConfig.API_URL + options.path;
    if (options.apiUrl) {
        options.url = options.apiUrl + options.path;
    }

    return options;
}
