import { ApiResponseBody, ApiResponseErrors } from 'types/request';

export const mapErrors = (responseBody: ApiResponseBody): ApiResponseErrors => {
    // Set validation errors
    let validationErrors = {};
    try {
        validationErrors = responseBody.data?.errors || {};
        Object.keys(validationErrors).forEach((field: string) => {
            const finalKey = field.split('.');
            const finalKeyString = finalKey[0];
            validationErrors[finalKeyString] = Array.isArray(validationErrors[field])
                ? validationErrors[field].join(',')
                : validationErrors[field];
        });
    } catch (error) {
        validationErrors = {};
    }

    return {
        originalError: responseBody || null,
        statusText: responseBody?.statusText || 'ERROR',
        code: responseBody?.status || 0,
        validationErrors: validationErrors || {},
    };
};

export function sleep(ms = 100) {
    return new Promise((res) => setTimeout(res, ms));
}