import React, { useEffect } from 'react';

import { ApiResources } from 'types/apiResources';

import { COOKIE_AUTH_TOKEN } from 'consts/cookies';
import { getProvider } from 'services/Cookies';

import { getProfile } from 'requests/customers';

import ProfileContext from 'components/context/Profile';
import useContextProfile from 'hooks/useContextProfile';

import { Props } from './index';
import StyledComponent from './styles';

const WrapperAuth: React.FC<Props> = ({ children }) => {
    const profileContext = useContextProfile();

    useEffect(() => {
        authenticate();
    }, []);

    const authenticate = () => {
        const cookieAuthToken = getProvider().get(COOKIE_AUTH_TOKEN);

        if (cookieAuthToken && !profileContext.profile) {
            getProfile({ authToken: cookieAuthToken })
                .then((res) => {
                    profileContext.updateProfile({ ...res[ApiResources.Me] });
                });
        }
    };

    return (
        <StyledComponent className="wrapper-auth">
            <ProfileContext.Provider value={profileContext}>
                <div className="wrapper-content">
                    {children}
                </div>
            </ProfileContext.Provider>
        </StyledComponent>
    );
};

export default WrapperAuth;
