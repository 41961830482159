import ApiCustomer from 'types/api/Customer';
import { ApiResources } from 'types/apiResources';
import { Cookies } from 'types/cookies';
import { Endpoints } from 'types/endpoints';

import Customer from 'models/Customer';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';
import { destroySession } from 'services/Auth';
import { getProvider } from 'services/Cookies';


export interface RegisterParams {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
}

export interface RegisterResponse {
    [ApiResources.Me]: Customer;
}

export const register = async (params: RegisterParams): Promise<RegisterResponse> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicCustomers, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ApiCustomer = data && data[ApiResources.Customer];

    if (!data || !resource) {
        return null;
    }

    return {
        [ApiResources.Me]: new Customer(resource),
    };
};


export interface ProfileGetParams {
}

export interface ProfileGetResponse {
    [ApiResources.Me]: Customer;
}

export const getProfile = async (params?: ProfileGetParams): Promise<ProfileGetResponse> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCustomerAuthMe, params),
        requestParams: params,
        headers: {
            'authorization': `Bearer ${getProvider().get(Cookies.AuthToken) || ''}`,
        },
    });

    const data = response?.payload?.data;
    const customerResource: ApiCustomer = data && data[ApiResources.Me];

    if (!data || !customerResource) {
        return null;
    }

    return {
        [ApiResources.Me]: new Customer(customerResource),
    };
};

export interface LogoutParams {}
export const logout = async (params?: LogoutParams) => {
    const response = await request({
        method: 'DELETE',
        path: withVariables(Endpoints.PublicCustomersToken, params),
        params,
    })
        .then(response => {
            destroySession();
            return response;
        })
        .catch(error => {
            console.error(error);

            destroySession();
            throw error;
        });
};


export interface LoginParams {
    email: string;
    password: string;
}

export interface LoginResponse {
    [ApiResources.AuthToken]: string;
    [ApiResources.Me]: Customer;
}

export const login = async (params: LoginParams): Promise<LoginResponse> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicCustomersToken, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const customerResource: ApiCustomer = data && data[ApiResources.Me];
    const authTokenResource: string = data && data[ApiResources.AuthToken];

    if (!data || !customerResource || !authTokenResource) {
        return null;
    }

    return {
        [ApiResources.AuthToken]: authTokenResource,
        [ApiResources.Me]: new Customer(customerResource),
    };
};

export interface PasswordResetParams {
    email: string;
}

export interface PasswordResetResponse {
    [ApiResources.Status]: boolean;
}

export const passwordReset = async (params: PasswordResetParams): Promise<PasswordResetResponse> => {
    const response = await request({
        method: 'DELETE',
        path: withVariables(Endpoints.PublicCustomersPassword, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: boolean = data && data[ApiResources.Status];

    if (!data || !resource) {
        return null;
    }

    return {
        [ApiResources.Status]: resource,
    };
};

export interface PasswordChangeParams {
    token: string;
    password: string;
    passwordConfirm: string;
}

export interface PasswordChangeResponse {
    [ApiResources.Me]: Customer;
    [ApiResources.AuthToken]: string;
}

export const passwordChange = async (params: PasswordChangeParams): Promise<PasswordChangeResponse> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicCustomersPassword, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const customerResource: ApiCustomer = data && data[ApiResources.Me];
    const authTokenResource: string = data && data[ApiResources.AuthToken];

    if (!data || !customerResource || !authTokenResource) {
        return null;
    }

    return {
        [ApiResources.Me]: new Customer(customerResource),
        [ApiResources.AuthToken]: authTokenResource,
    };
};

export interface CreateLeadParams {
    email: string;
}

export interface CreateLeadResponse {
    [ApiResources.Me]: Customer;
}

export const createLead = async (params: CreateLeadParams): Promise<CreateLeadResponse> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicCustomersCreateLead, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ApiCustomer = data && data[ApiResources.Me];

    if (!data || !resource) {
        return null;
    }

    return {
        [ApiResources.Me]: new Customer(resource),
    };
};

export interface MailingStatusParams {
    customerId: string | string[];
    status: string | string[];
}

export interface MailingStatusResponse {
    [ApiResources.Status]: boolean;
}

export const mailingStatus = async (params: MailingStatusParams): Promise<MailingStatusResponse> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicCustomersMailingStatus, params),
        requestParams: params,
        timeout: 10000,
    });

    const data = response?.payload?.data;
    const resource: boolean = data && data[ApiResources.Status];

    if (!data || !resource) {
        return null;
    }

    return {
        [ApiResources.Status]: resource,
    };
};
