export enum Endpoints {
    /**
     * Public module
     */

    // Pages
    PublicPage = '/pages/single',


    // Customers
    PublicCustomers = '/customers',
    PublicCustomerAuthMe = '/customer/me',
    PublicCustomersToken = '/customers/token',
    PublicCustomersPassword = '/customers/password',
    PublicCustomersCreateLead = '/customers/lead',
    PublicCustomersMailingStatus = '/customers/mailing-status',

    // Partners
    PublicPartners = '/partners',
    PublicPartnersToken = '/partners/token',
    PublicPartnersPassword = '/partners/password',

    // Courses
    PublicCourseCategories = '/course-categories',
    PublicCourseCategory = '/course-categories/:id',

    // Certificates
    PublicCertificate = '/partner-certificates/:id',

    // Articles
    PublicArticles = '/articles',
    PublicArticle = '/articles/:slug',

    // Orders
    PublicOrders = '/orders',
    PublicOrder = '/orders/:id',

    // PaymentTransactions
    PublicPaymentTransaction = '/payment-transactions/:id',

    // Plans
    PublicPlans = '/plans',
    PublicPlan = '/plans/:id',

    // Order payment
    PublicOrderPaymentMakePayment = '/orders/:id/payment',

    // Survey
    PublicSurvey = '/partner-npses/load',
    PublicSurveySend = '/partner-nps-customer-answers',

    // Courses
    PublicCourses = '/courses',
    PublicCourse = '/courses/single',

    // Companies
    PublicCompanies = '/partners',
    PublicCompany = '/partners/:slug',
    PublicConductList = '/leads',
    PublicConduct = '/leads/:slug',


    // Customer course schedule customers
    CustomerCourseScheduleCustomer = '/customer/course-schedule-customers',

    // Customer customer liked courses
    CustomerCustomerLikedCourse = '/customer/customer-liked-courses/:id',

    // Contacts
    PublicContacts = '/contacts',
}

export interface ListParamsDefault {
    page?: number;
    perPage?: number;
    search?: string;
}
