import ApiCustomer from 'types/api/Customer';

import Model from 'models/Model';
import User from 'models/User';

// why not used already exist interfaces/models because we'll create new smaller objects especially for this case
export interface CourseSchedule {
    id: string;
    name: string;
}

export interface LikedCourses {
    id: string;
    name: string;
}

export default class Customer implements Model {
    id: string;
    user: User;
    courseScheduleCustomers: CourseSchedule[];
    customerLikedCourses: LikedCourses[];

    constructor(data: ApiCustomer) {
        this.id = data.id;
        this.user = data.user && new User(data.user);
        this.courseScheduleCustomers = Array.isArray(data.courseScheduleCustomers) ? data.courseScheduleCustomers.map(schedule => schedule) : null;
        this.customerLikedCourses = Array.isArray(data.customerLikedCourses) ? data.customerLikedCourses.map(course => course) : null;
    }
}
