import { useState } from 'react';

const useProfile = () => {
    const [profile, setProfile] = useState(null);

    const updateProfile = (newProfile) => {
        setProfile(newProfile);
    };

    return {
        profile,
        updateProfile,
    };
};

export default useProfile;
